export async function loadContent(baseUrl: string, params: Record<string, string>): Promise<string> {
    const url = new URL(baseUrl, location.origin)
    for (const key in params) {
        url.searchParams.set(key, params[key])
    }
    try {
        const response = await fetch(url)
        return await response.text()
    } catch (e) {
        console.error(e)
        return "error"
    }
}
