import { library,dom } from '@fortawesome/fontawesome-svg-core'
import {
    faSquareMinus as faSquareMinusProLight,
    faSquarePlus as faSquarePlusProLight,
    faCheck as faCheckProLight,
    faFileCode as faFileCodeProLight,
    faFileLines as faFileLinesProLight,
} from '@fortawesome/pro-light-svg-icons'
import {
    faSquareMinus as faSquareMinusFreeSolid,
    faSquarePlus as faSquarePlusFreeSolid,
    faLink as faLinkFreeSolid,
    // faTextHeight as faTextHeightFreeSolid,
    // faBold as faBoldFreeSolid,
    // faItalic as faItalicFreeSolid,
    // faStrikethrough as faStrikethroughFreeSolid,
    // faUnderline as faUnderlineFreeSolid,
    // faHeading as faHeadingFreeSolid,
    // faListUl as faListUlFreeSolid,
    // faListOl as faListOlFreeSolid,
    // faAlignLeft as faAlignLeft,
    // faAlignCenter as faAlignCenter,
    // faAlignRight as faAlignRightFreeSolid,
    // faQuoteLeft as faQuoteLeftFreeSolid,
    // faTable as faTableFreeSolid,
    faPaperclip as faPaperclipFreeSolid,
    // faWindowClose as faWindowCloseFreeSolid,
    // faUndo as faUndoFreeSolid,
    // faRedo as faRedoFreeSolid,
    // faCut as faCutFreeSolid,
    faCopy as faCopyFreeSolid,
    // faSearch as faSearchFreeSolid,
    faExclamationTriangle as faExclamationTriangleFreeSolid,
    // faExternalLink as faExternalLinkFreeSolid,
    // faLock as faLockFreeSolid,
    faChevronDown as faChevronDownFreeSolid,
    faChevronLeft as faChevronLeftFreeSolid,
    faChevronRight as faChevronRightFreeSolid,
    faChevronUp as faChevronUpFreeSolid,
    // faSignIn as faSignInFreeSolid,
    // faSignOut as faSignOutFreeSolid,
    // faHandPointRight as faHandPointRightFreeSolid,
    // faSync as faSyncFreeSolid,
    faQuestionCircle as faQuestionCircleFreeSolid,
    faArrowRight as faArrowRightFreeSolid,
    faStopwatch as faStopwatchFreeSolid,
    faMemory as faMemoryFreeSolid,
    faTachometerAlt as faTachometerAltFreeSolid,
    faExpandAlt as faExpandAltFreeSolid,
    faCog as faCogFreeSolid,
    // faFastBackward as faFastBackwardFreeSolid,
    // faCaretLeft as faCaretLeftFreeSolid,
    // faCaretRight as faCaretRightFreeSolid,
    // faCaretDown as faCaretDownFreeSolid,
    // faStepBackward as faStepBackwardFreeSolid,
    // faStepForward as faStepForwardFreeSolid,
    // faFilter as faFilterFreeSolid,
    // faEdit as faEditFreeSolid,
    faInfoCircle as faInfoCircleFreeSolid,
    faTruck as faTruckFreeSolid,
    faPencil as faPencilFreeSolid,
    faTimes as faTimesFreeSolid,
    faArrowUp as faArrowUpFreeSolid,
    faArrowDown as faArrowDownFreeSolid,
    faWrench as faWrenchFreeSolid,
    // faRectangleAd as faRectangleAdFreeSolid,
    // faHouseFire as faHouseFireFreeSolid,
    // faEyedropper as faEyedropperFreeSolid,
    // faAngleUp as faAngleUpFreeSolid,
    faSpinner as faSpinnerFreeSolid,
    faArrowUpRightFromSquare as faArrowUpRightFromSquareFreeSolid,
    faEnvelope as faEnvelopeFreeSolid,
    faShareNodes as faShareNodesFreeSolid,
    faShareFromSquare as faShareFromSquareFreeSolid,
} from '@fortawesome/free-solid-svg-icons'
import {
    // faHorizontalRule as faHorizontalRuleProSolid,
    faLockAlt as faLockAltProSolid,
    faArrowAltDown as faArrowAltDownProSolid,
    faFileSignature as faFileSignatureProSolid,
    // faHatWizard as faHatWizardProSolid,
    // faMessageExclamation as faMessageExclamationProSolid,
    // faBlockQuestion as faBlockQuestionProSolid,
    faSquareQuote as faSquareQuoteProSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {
    // faFaceLaugh as faFaceLaughFreeRegular,
    faHdd as faHddFreeRegular,
    faCircleXmark as faCircleXmarkFreeRegular,
} from '@fortawesome/free-regular-svg-icons'
// import {
//     faExternalLink as faExternalLinkProRegular,
// } from '@fortawesome/pro-regular-svg-icons'
import {
    // faYoutube as faYoutubeFreeBrands,
    faTwitter as faTwitterFreeBrands,
    faSquareXTwitter as faSquareXTwitterFreeBrands,
    faFacebookSquare as faFacebookSquareFreeBrands,
    faLine as faLineFreeBrands,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faSquarePlusProLight,
    faSquareMinusProLight,
    faSquarePlusFreeSolid,
    faSquareMinusFreeSolid,
    faLinkFreeSolid,
    // faTextHeightFreeSolid,
    // faBoldFreeSolid,
    // faItalicFreeSolid,
    // faStrikethroughFreeSolid,
    // faUnderlineFreeSolid,
    // faHeadingFreeSolid,
    // faListUlFreeSolid,
    // faListOlFreeSolid,
    // faAlignLeft,
    // faAlignCenter,
    // faAlignRightFreeSolid,
    // faQuoteLeftFreeSolid,
    // faTableFreeSolid,
    // faHorizontalRuleProSolid,
    faPaperclipFreeSolid,
    // faYoutubeFreeBrands,
    faTwitterFreeBrands,
    faSquareXTwitterFreeBrands,
    // faFaceLaughFreeRegular,
    // faWindowCloseFreeSolid,
    // faUndoFreeSolid,
    // faRedoFreeSolid,
    // faCutFreeSolid,
    faCopyFreeSolid,
    // faSearchFreeSolid,
    faExclamationTriangleFreeSolid,
    // faExternalLinkFreeSolid,
    // faLockFreeSolid,
    faChevronDownFreeSolid,
    faChevronLeftFreeSolid,
    faChevronRightFreeSolid,
    faChevronUpFreeSolid,
    // faSignInFreeSolid,
    // faSignOutFreeSolid,
    // faHandPointRightFreeSolid,
    // faSyncFreeSolid,
    faQuestionCircleFreeSolid,
    faArrowRightFreeSolid,
    faCheckProLight,
    faFileCodeProLight,
    faFileLinesProLight,
    faStopwatchFreeSolid,
    faMemoryFreeSolid,
    faHddFreeRegular,
    faTachometerAltFreeSolid,
    faExpandAltFreeSolid,
    faCogFreeSolid,
    faLockAltProSolid,
    faArrowAltDownProSolid,
    // faExternalLinkProRegular,
    // faFastBackwardFreeSolid,
    // faCaretLeftFreeSolid,
    // faCaretRightFreeSolid,
    // faCaretDownFreeSolid,
    // faStepBackwardFreeSolid,
    // faStepForwardFreeSolid,
    // faFilterFreeSolid,
    // faEditFreeSolid,
    faInfoCircleFreeSolid,
    faFileSignatureProSolid,
    faTruckFreeSolid,
    faPencilFreeSolid,
    faTimesFreeSolid,
    faArrowUpFreeSolid,
    faArrowDownFreeSolid,
    faWrenchFreeSolid,
    // faRectangleAdFreeSolid,
    // faHatWizardProSolid,
    // faHouseFireFreeSolid,
    // faMessageExclamationProSolid,
    // faEyedropperFreeSolid,
    faSpinnerFreeSolid,
    faArrowUpRightFromSquareFreeSolid,
    // faBlockQuestionProSolid,
    // faAngleUpFreeSolid,
    faSquareQuoteProSolid,
    faEnvelopeFreeSolid,
    faCircleXmarkFreeRegular,
    faShareNodesFreeSolid,
    faFacebookSquareFreeBrands,
    faLineFreeBrands,
    faShareFromSquareFreeSolid
);

require('./fonts-extra');

dom.watch()
