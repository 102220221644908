import {loadContent} from './lib/lazy-load'

$.fn.extend({
    'lazyFold': function (this: JQuery, options) {
        options = $.extend({
            baseUrl: '',
            toggleButtonSelector: '',
            openButtonSelector: '',
            targetSelector: '',
            openClassName: '',
        }, options)

        this.each(function (this: HTMLElement) {
            const $self = $(this)
            const $toggleButton = $self.children(options.toggleButtonSelector)
            const $openButton = $self.children(options.openButtonSelector)
            const $target = $self.children(options.targetSelector)

            $toggleButton.on('click', function () {
                $self.toggleClass(options.openClassName)
            })
            $openButton.one('click', function () {
                loadContent(options.baseUrl, {
                    page: $self.data('page') ?? "",
                    id: $self.data('id') ?? "",
                    key: $self.data('key') ?? "",
                    hash: $self.data('hash') ?? "",
                }).then((content) => {
                    $target.html(content)
                    window.bootLazy.bootOn($target[0])
                })
            })
        })
    },
})
