import {loadContent} from './lib/lazy-load'

$.fn.extend({
    'lazyAccordion': function (this: JQuery, options) {
        options = $.extend({
            baseUrl: '',
            headerSelector: '',
            contentSelector: '',
            openClassName: '',
        }, options)

        this.each(function (this: HTMLElement) {
            const $self = $(this)
            const $header = $self.children(options.headerSelector)
            const $content = $self.children(options.contentSelector)

            $header.on('click', function () {
                $content.slideToggle('fast', function () {
                    $self.toggleClass(options.openClassName)
                })
                return false
            })
            $header.one('click', function () {
                loadContent(options.baseUrl, {
                    page: $self.data('page') ?? "",
                    id: $self.data('id') ?? "",
                    key: $self.data('key') ?? "",
                    hash: $self.data('hash') ?? "",
                }).then((content) => {
                    $content.html(content)
                    window.bootLazy.bootOn($content[0])
                })
            })
        })
    },
})
